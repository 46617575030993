import { graphql } from 'gatsby'
import * as React from 'react'
import News, { Props } from './news'

export const query = graphql`
  query EnIndexNewsQuery {
    allContentXlsxEnNews {
      nodes {
        title
        date
        content
        pictures
      }
    }
    allContentXlsxSlider {
      nodes {
        picture
      }
    }
  }
`
const Index = (props: Props) => <News {...props} />
export default Index
