import { makeStyles } from '@material-ui/core'
import { graphql } from 'gatsby'
import * as React from 'react'
import { ReactElement } from 'react'
import Layout from '../../components/Layout'
import { NewsComponent } from '../../components/NewsComponent'
import Slider from '../../components/Slider'

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
    fontSize: '1.5em',
    marginLeft: '10px'
  }
})
export const query = graphql`
  query EnNewsQuery {
    allContentXlsxEnNews {
      nodes {
        title
        date
        content
        pictures
      }
    }
    allContentXlsxSlider {
      nodes {
        picture
      }
    }
  }
`

interface News {
  title: string
  date: string
  content: string
  pictures: string
}

interface Picture {
  picture: string
}
interface ISlider {
  nodes: Array<Picture>
}

interface Nodes {
  nodes: Array<News>
}

interface DataSheet {
  allContentXlsxEnNews: Nodes
  allContentXlsxSlider: ISlider
}

export interface Props {
  data: DataSheet
}

const News = ({
  data: {
    allContentXlsxEnNews: { nodes: newsList },
    allContentXlsxSlider: { nodes: pictures }
  }
}: Props): ReactElement => {
  const styles = useStyles()
  return (
    <Layout isEn actualTab="News">
      <>
        <Slider pictures={pictures} />
        <p className={styles.title}>News</p>
        <NewsComponent newsList={newsList} />
      </>
    </Layout>
  )
}

export default News
